import { useService } from "@/services";
import { useQuery } from "@tanstack/react-query";

export function useStates() {
  const { address } = useService();

  const { data: states = [], ...rest } = useQuery({
    queryKey: ["states"],
    queryFn: () => address.getStates(),
  });

  return {
    states,
    ...rest,
  };
}
