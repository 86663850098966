import { SocketAdapter as Adapter } from "@/@types";
import { io, Socket } from "socket.io-client";

export class SocketAdapter extends Adapter {
  private instance: Socket;

  constructor(baseUrl: string) {
    super();

    this.instance = io(baseUrl, {
      autoConnect: import.meta.env.MODE !== "test",
    });

    this.instance.on("connect", () => {
      console.log("Socket connected");
    });
  }

  connect() {
    this.instance.connect();
  }

  disconnect() {
    this.instance.disconnect();
  }

  on(event: string, listener: (...args: any[]) => void) {
    this.instance.on(event, listener);
  }

  off(event: string, listener: (...args: any[]) => void) {
    this.instance.off(event, listener);
  }
}
