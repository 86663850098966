import { Adapter } from "@/@types";
import {
  CreateSubscriptionPayload,
  PreApprovalPlan,
  Subscription,
} from "@/@types/subscription";

export class SubscriptionService {
  constructor(private readonly http: Adapter) {}

  async getPreApprovalPlans(phoneNumber: string): Promise<PreApprovalPlan[]> {
    return this.http
      .get(`/v1/checkout/pre-approvals/${phoneNumber}/plans`)
      .then((response) => response.data);
  }

  async createSubscription(
    payload: CreateSubscriptionPayload
  ): Promise<Subscription> {
    return this.http
      .post(`/v1/subscriptions`, payload)
      .then((response) => response.data);
  }
}
