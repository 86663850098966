import { create } from "zustand";

interface OnboardingState {
  onboardingEnabled: boolean;
  setOnboardingEnabled: (enabled: boolean) => void;
}

const useOnboardingStore = create<OnboardingState>((set) => ({
  onboardingEnabled: false,
  setOnboardingEnabled: (enabled) => set({ onboardingEnabled: enabled }),
}));

export default useOnboardingStore;
