import * as React from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const Toolbar = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "fixed bottom-4 left-1/2 -translate-x-1/2 bg-zinc-900 rounded-full p-1.5 px-1.5 flex items-center gap-1 animate-[slideUp_0.3s_ease-out_forwards]",
      className
    )}
    {...props}
  />
));

Toolbar.displayName = "Toolbar";

const ToolbarItemCount = ({
  count,
  className,
  ...props
}: { count: number } & React.HTMLAttributes<HTMLSpanElement>) => (
  <>
    <span className={cn("text-sm text-white ml-4 mr-2", className)} {...props}>
      {count} {count === 1 ? "item selecionado" : "itens selecionados"}
    </span>
  </>
);

ToolbarItemCount.displayName = "ToolbarItemCount";

const ToolbarButton = ({
  icon: Icon,
  label,
  onClick,
  className,
  ...props
}: {
  icon: React.ElementType;
  label: string;
  onClick?: () => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className={cn("hover:bg-zinc-700 rounded-full", className)}
          onClick={onClick}
          {...props}
        >
          <Icon size={24} fill="white" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

ToolbarButton.displayName = "ToolbarButton";

export { Toolbar, ToolbarItemCount, ToolbarButton };
