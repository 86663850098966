import * as React from "react";
import { Input, InputProps } from "./input";

export interface InputNumberProps extends Omit<InputProps, "mask" | "value"> {
  value?: number;
  allowNegative?: boolean;
}

function inputPositiveNumberParser(value?: string) {
  return value ? parseInt(value.replace(/\D/g, "")) : 0;
}

function inputNegativeNumberParser(value?: string) {
  return value ? parseInt(value.replace(/[^-\d]/g, "")) : 0;
}

const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  ({ value, onChange, allowNegative = false, max, ...props }, ref) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const value = event.target.value;

        if (!!max && Number(value) > Number(max)) return;

        onChange({
          ...event,
          target: {
            ...event.target,
            // @ts-ignore
            value: allowNegative
              ? inputNegativeNumberParser(value)
              : inputPositiveNumberParser(value),
          },
        });
      }
    }

    return (
      <Input ref={ref} value={value || ""} onChange={handleChange} {...props} />
    );
  }
);
InputNumber.displayName = "InputNumber";

export { InputNumber };
