import { analytics } from "@/adapters";
import { useMemo } from "react";

export enum FeatureFlagKey {
  NF_RECEIVED = "nf_received",
  PUSH_NOTIFICATION = "push_notification",
  WEBVIEW_IS_READY = "webview_is_ready",
  TAXES = "taxes",
  IN_QUEUE_PHASE = "in_queue_phase",
  SELF_ONBOARDING = "self-onboarding",
  BETA_TESTERS = "beta_testers",
  OPERATOR = "operator",
  CAN_USE_APP = "can_use_app",
  BILLS_TO_PAY = "bills_to_pay",
  TEMP_ME_399 = "temp_me_399",
  ANNUAL_BILLING_STATEMENTS = "annual_billing_statements",
  GUIDED_ONBOARDING = "guided_onboarding",
}

const experiment = analytics.getExperiment();

export function useFeatureFlag(flagKey: FeatureFlagKey) {
  const enabled = useMemo(() => {
    if (import.meta.env.MODE !== "production") return true;

    const variant = experiment?.variant(flagKey);

    return variant?.value === "on";
  }, [flagKey]);

  return {
    enabled,
  };
}
