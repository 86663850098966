import { useService } from "@/services";
import { useMutation } from "@tanstack/react-query";

export function useAddress() {
  const { address } = useService();

  const { mutateAsync: getAddress, ...rest } = useMutation({
    mutationFn: (postalCode: string) => address.getAddress(postalCode),
  });

  return {
    getAddress,
    ...rest,
  };
}
