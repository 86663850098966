"use client";

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { CircleNotch } from "@phosphor-icons/react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex font-medium transition-all items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default:
          "bg-blue dark:bg-blue-200 text-primary-foreground dark:text-white hover:bg-blue-800 dark:hover:bg-blue-100 disabled:bg-gray-300",
        light:
          "bg-blue-50 text-blue hover:bg-blue-100 disabled:bg-blue-600 disabled:text-blue-200",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground disabled:opacity-50",
        ghost: "hover:bg-gray-50 text-blue disabled:opacity-50",
        success:
          "bg-green-600 text-white hover:bg-green-700 disabled:opacity-50",
        destructive:
          "bg-red-600 text-white hover:bg-red-700 disabled:opacity-50",
        dashed:
          "bg-gray-50 border border-gray-300 border-dashed text-blue-700 hover:bg-gray-100 hover:border-gray-400",
      },
      size: {
        default: "h-12 px-6 py-2 gap-3",
        icon: "h-12 w-12",
        small: "h-8 px-3 text-xs rounded-[12px] gap-2",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      disabled,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <div>
            <CircleNotch
              size={20}
              aria-label="Carregando"
              className="animate-spin"
            />
          </div>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
