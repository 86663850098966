import { Adapter } from "@/@types";
import { RegisterPayload } from "@/@types/auth";
import { UserProfile } from "@/@types/user";
import { URL_LOGIN } from "@/constants/urls";
import { redirect } from "react-router";

export class AuthService {
  private interceptorId: number | undefined;

  constructor(private readonly http: Adapter) {
    this.enableRefreshToken();
  }

  signOut() {
    localStorage.removeItem("@setfin:token");
    localStorage.removeItem("@setfin:refresh-token");
    redirect(URL_LOGIN);
  }

  enableRefreshToken() {
    this.http.setInterceptorResponseHeaders(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem("@setfin:refresh-token");

        if (
          error.response?.status === 401 &&
          !originalRequest._retry &&
          refreshToken &&
          originalRequest.baseUrl === process.env.VITE_CONNECT_API_URL
        ) {
          originalRequest._retry = true;
          try {
            const refreshToken = localStorage.getItem("@setfin:refresh-token");

            if (!refreshToken) {
              return this.signOut();
            }

            const response = await this.http.post("/v1/refresh-token", {
              token: refreshToken,
            });

            const accessToken = response.data.accessToken;

            localStorage.setItem("@setfin:token", accessToken);
            localStorage.setItem(
              "@setfin:refresh-token",
              response.data.refreshToken
            );

            this.http.setDefaultsHeaders({
              Authorization: `Bearer ${accessToken}`,
            });

            return this.http.request({
              ...originalRequest,
              headers: {
                ...originalRequest,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          } catch (refreshError) {
            this.signOut();
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  async verifyPhone(phoneNumber: string): Promise<boolean> {
    return this.http
      .get(`/v1/phones/${phoneNumber}/valid`)
      .then((response) => response.data.exists);
  }

  async verifyEmail(email: string): Promise<boolean> {
    return this.http
      .get(`/v1/emails/${email}/valid`)
      .then((response) => response.data.exists);
  }

  async signInWithDocumentNumber(
    document_number: string,
    password: string
  ): Promise<{ accessToken: string; refreshToken: string; companyId: string }> {
    return this.http
      .post("/v1/authenticate", {
        document_number,
        password,
      })
      .then((response) => response.data);
  }

  async verifyOtp(phoneNumber: string, otp: string) {
    return this.http
      .post(`/v1/verify-otp/${phoneNumber}`, {
        otp,
      })
      .then((response) => response.data);
  }

  async register({
    email,
    phone_number,
    name,
    kind,
  }: RegisterPayload): Promise<UserProfile> {
    return this.http
      .post("/v1/register", {
        email,
        phone_number,
        name,
        kind,
      })
      .then((response) => response.data);
  }

  async sendOtp(phoneNumber: string) {
    return this.http.post(`/v1/send-otp/${phoneNumber}`);
  }

  async setAuthorizationHeader() {
    const token = localStorage.getItem("@setfin:token");

    if (token) {
      this.interceptorId = this.http.setInterceptorRequestHeaders(
        async (config: any) => {
          try {
            const accessToken = localStorage.getItem("@setfin:token");
            if (accessToken) {
              config.headers.Authorization = `Bearer ${accessToken}`;
            } else {
              delete config.headers.Authorization;
            }
            return config;
          } catch (e) {
            return config;
          }
        }
      );
    } else if (this.interceptorId) {
      this.http.removeInterceptorRequest(this.interceptorId);
    }
  }
}
