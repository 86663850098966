import { Adapter } from "@/@types";
import { Receivable } from "@/@types/entries";
import {
  ConciliationReceivablePayload,
  CreateReceivablePayload,
  ReceivableConciliation,
  UpdateReceivablePayload,
} from "@/@types/entries/receivable";

export class ReceivableService {
  constructor(private readonly http: Adapter) {}

  async createReceivable(
    companyId: string,
    payload: CreateReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .post(`/v1/companies/${companyId}/receivables`, payload)
      .then((response) => response.data);
  }

  async getConciliationReceivable(
    companyId: string,
    receivableId: string
  ): Promise<ReceivableConciliation> {
    return this.http
      .get(
        `/v1/companies/${companyId}/receivables/${receivableId}/conciliation`
      )
      .then((response) => response.data);
  }

  async conciliationReceivable(
    companyId: string,
    { receivable_id, ...payload }: ConciliationReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .post(
        `/v1/companies/${companyId}/receivables/${receivable_id}/conciliation`,
        payload
      )
      .then((response) => response.data);
  }

  async undoConciliationReceivable(
    companyId: string,
    { receivable_id, ...payload }: ConciliationReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .post(
        `/v1/companies/${companyId}/receivables/${receivable_id}/conciliation/undo`,
        payload
      )
      .then((response) => response.data);
  }

  async updateReceivable(
    companyId: string,
    { receivable_id, ...rest }: UpdateReceivablePayload
  ): Promise<Receivable> {
    return this.http
      .patch(`/v1/companies/${companyId}/receivables/${receivable_id}`, rest)
      .then((response) => response.data);
  }

  async getReceivable(companyId: string, billId: string): Promise<Receivable> {
    return this.http
      .get(`/v1/companies/${companyId}/receivables/${billId}`)
      .then((response) => response.data);
  }

  async getReceivables(
    companyId: string,
    start_date?: string,
    end_date?: string,
    order_by: "date" | "sorting_index" = "date"
  ): Promise<ReceivableConciliation[]> {
    return this.http
      .get(`/v1/companies/${companyId}/receivables`, {
        params: {
          start_date,
          end_date,
          order_by,
        },
      })
      .then((response) => response.data);
  }

  async deleteReceivable(
    companyId: string,
    billId: string
  ): Promise<Receivable> {
    return this.http
      .delete(`/v1/companies/${companyId}/receivables/${billId}`)
      .then((response) => response.data);
  }
}
