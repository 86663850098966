import { forwardRef } from "react";

import { Autocomplete, AutocompleteProps, Skeleton } from "@/components/ui";
import { useStates } from "../hooks";

type Props = Omit<AutocompleteProps, "options">;

export const StatesContainer = forwardRef<HTMLInputElement, Props>(
  function StatesContainer(inProps, ref) {
    const { states, isLoading } = useStates();

    if (isLoading) {
      return <Skeleton className="h-12 w-full" />;
    }

    return (
      <Autocomplete
        ref={ref}
        options={states.map((state) => ({
          label: state.uf,
          value: state.uf,
        }))}
        {...inProps}
        loading={isLoading}
        empty="Estado não encontrado"
      />
    );
  }
);
