import { ChangeEvent, FocusEvent, forwardRef } from "react";

import { Address } from "@/@types/address";
import { Input, InputProps } from "@/components/ui";

import { useAddress } from "../hooks";

type Props = {
  onSuccess: (address: Address) => void;
} & InputProps;

export const InputPostalCodeContainer = forwardRef<HTMLInputElement, Props>(
  function InputPostalCodeContainer(inProps, ref) {
    const { onSuccess, value, onChange, onBlur, ...rest } = inProps;

    const { getAddress, isPending } = useAddress();

    function handleBlur(event: FocusEvent<HTMLInputElement>) {
      onBlur?.(event);

      if (!value || typeof value !== "string" || value.length !== 9) return;

      getAddress(value)
        .then(onSuccess)
        .catch(() => null);
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
      onChange?.(event);

      if (
        !event.currentTarget.value ||
        typeof event.currentTarget.value !== "string" ||
        event.currentTarget.value.length !== 9
      )
        return;

      getAddress(event.currentTarget.value)
        .then(onSuccess)
        .catch(() => null);
    }

    return (
      <Input
        ref={ref}
        mask={["99999-999"]}
        loading={isPending}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        {...rest}
      />
    );
  }
);
