import { Adapter } from "@/@types";

export class TutorialService {
  constructor(private readonly http: Adapter) {}

  async createTutorial(type: string) {
    return this.http
      .post(`/v1/tutorials`, {
        type,
      })
      .then((response) => response.data);
  }

  async completeTutorial(tutorialId: string) {
    return this.http.patch(`/v1/tutorials/${tutorialId}`);
  }

  async getUserTutorials(userId: string) {
    return this.http
      .get(`/v1/tutorials`, { params: { userId } })
      .then((response) => response.data);
  }
}
