import { useService } from "@/services";
import { useQuery } from "@tanstack/react-query";

export function useCities(state?: string | null) {
  const { address } = useService();

  const { data: cities = [], ...rest } = useQuery({
    enabled: Boolean(state),
    queryKey: ["cities", state],
    queryFn: () => address.getCities(state!),
  });

  return {
    cities,
    ...rest,
  };
}
